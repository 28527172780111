import { useRef, useState, useEffect } from "react";
import Player from "@vimeo/player";
import "../styles/globals.css";
import React from "react";
import Head from "../components/Head";
import playIcon from "../images/Play.svg";
import pauseIcon from "../images/Pause.svg";

const Home = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [player, setPlayer] = useState<Player | null>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const newPlayer = new Player(iframeRef.current);
      setPlayer(newPlayer);

      newPlayer.on("play", () => {
        setIsPlaying(true);
      });

      newPlayer.on("pause", () => {
        setIsPlaying(false);
      });
    }

    return () => {
      if (player) {
        player.off("play");
        player.off("pause");
      }
    };
  }, [iframeRef, player]);

  const togglePlay = () => {
    if (!player) return;

    if (isPlaying) {
      player
        .pause()
        .then(() => {
          setIsPlaying(false);
        })
        .catch((error: Error) => {
          console.error("Error pausing the video:", error);
        });
    } else {
      player
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error: Error) => {
          console.error("Error playing the video:", error);
        });
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Head title={"Colezze | Official Website"} />
      <div className="videoBackground">
        <button className={`pauseButton`} onClick={togglePlay}>
          <span className={isPlaying ? "pauseIcon" : "playIcon"}>
            {isPlaying ? (
              <div style={{ marginTop: "5px" }}>
                <img 
                  src={pauseIcon} 
                />
              </div>
            ) : (
              <img 
                src={playIcon} 
              />
            )}
          </span>
        </button>
        <iframe
          ref={iframeRef}
          className="videoIframe"
          src="https://player.vimeo.com/video/925403936?background=1&autoplay=1&muted=1"
          allow="autoplay; fullscreen"
          title="Background"
        ></iframe>
      </div>
      {/* Additional content */}
      <div className="ourStory">
        <h2>Our Story</h2>
        <p>
          Founded in 2023, Colezze is a luxury fashion brand driven by a passion for exquisite design,
          exceptional craftsmanship, and a commitment to sustainable luxury. Our journey began with the
          vision of blending traditional techniques with modern aesthetics, aspiring to create timeless pieces
          for the discerning individual. As we embark on this journey, our creativity and innovation continue to
          evolve. This coming autumn-winter, we are excited to unveil our 2025 collection, a curated selection
          of garments designed to inspire anticipation and allure. Scheduled for release in October/November,
          this collection reflects our belief that every piece of clothing should not only be worn but looked
          forward to – a celebration of personal style and individuality. Join us as we craft our story, one
          garment at a time, with the goal of turning every piece into a cherished wardrobe highlight.
        </p>
      </div>
    </div>
  );
};

export default Home;