import { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import React from "react";
import { Link } from "react-router-dom";
import "../styles/globals.css";
import { getSubscriber, subscribeToNewsletter } from '../services/api';

type FormValues = {
  email: string;
};

const Footer = () => {
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const mouseDownInsidePopup = useRef(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [formValues, setFormValues] = useState<FormValues | null>(null);
  const [formikHelpers, setFormikHelpers] = useState<FormikHelpers<FormValues> | null>(null);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const [showSignUp, setShowSignUp] = useState(true);

  const handleContainerMouseDown = (event: React.MouseEvent) => {
    mouseDownInsidePopup.current = event.target !== event.currentTarget;
  };

  const handleContainerMouseUp = () => {
    if (!mouseDownInsidePopup.current) {
      if (formikHelpers) {
        if (emailInputRef.current) emailInputRef.current.blur();
        formikHelpers.resetForm();
        formikHelpers.setSubmitting(false);
      }
      setPopupMessage(null);
      setShowSignUp(true);
    }
  };

  const handleContentMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    mouseDownInsidePopup.current = true;
  };

  const handleContentMouseUp = (event: React.MouseEvent) => {
    event.stopPropagation();
    mouseDownInsidePopup.current = true;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email address is required")
      .matches(
        /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/gi,
        "Please enter a valid email address"
      ),
  });

  const initialValues: FormValues = {
    email: "",
  };

  const handleSubmitLogic = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    try {
      await getSubscriber(values.email);
      setPopupMessage("This email is already subscribed.");
    } catch (error) {
      try {
        await subscribeToNewsletter(values.email);
        setPopupMessage("Thank you for subscribing.");
      } catch (subscribeError) {
        setPopupMessage("Failed to subscribe. Please try again later.");
      }
    } finally {
      if (emailInputRef.current) emailInputRef.current.blur();
      resetForm();
      setSubmitting(false);
      setShowSignUp(false);
    }
  };

  const handleSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    setFormValues(values);
    setFormikHelpers(formikHelpers);
    setIsPrivacyAccepted(false);
    setPopupMessage("To proceed, please tick the checkbox confirming that you have read the Privacy Policy.");
  };

  const handlePopupSignUp = () => {
    if (formValues && formikHelpers) {
      handleSubmitLogic(formValues, formikHelpers);
    }
  };

  const handlePopupClose = () => {
    if (formikHelpers) {
      if (emailInputRef.current) emailInputRef.current.blur();
      formikHelpers.resetForm();
      formikHelpers.setSubmitting(false);
    }
    setPopupMessage(null);
    setShowSignUp(true);
  };

  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivacyAccepted(event.target.checked);
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form className="formInput">
            <div
              className={`${
                touched.email && errors.email ? "errorContainerNewsletter" : ""
              }`}
            >
              <div className="promoTextContainerNewsletter">
                <p className="promoTextNewsletter">
                  BE THE FIRST TO ACCESS COLEZZE LAUNCH
                </p>
              </div>
              <div className="input">
                <Field
                  innerRef={emailInputRef}
                  type="email"
                  name="email"
                  className={`newsletterForm ${
                    touched.email && errors.email ? "errorInputNewsletter" : ""
                  }`}
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  className="submitButtonNewsletter"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Signing up..." : "Sign up"}
                </button>
              </div>
              <div style={{ height: "20px" }}>
                <ErrorMessage
                  name="email"
                  component="p"
                  className="errorTextNewsletter"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {popupMessage && (
        <div
          className="popupContainer"
          onMouseDown={handleContainerMouseDown}
          onMouseUp={handleContainerMouseUp}
        >
          <div
            className="popupContent"
            onMouseDown={handleContentMouseDown}
            onMouseUp={handleContentMouseUp}
          >
            <p className="popupTitle">SUBSCRIBE TO THE COLEZZE NEWSLETTERS</p>
            <hr className="divider" />
            <p className="popupMessage">{popupMessage}</p>
            {showSignUp && (
              <>
                <div className="privacyContainer">
                  <input
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    onChange={handlePrivacyChange}
                    checked={isPrivacyAccepted}
                  />
                  <label htmlFor="privacy">I have read the <a href="/privacy">Privacy Policy</a></label>
                </div>
                <div className="popupButtonsContainer">
                  <button onClick={handlePopupClose}>Close</button>
                  <button onClick={handlePopupSignUp} disabled={!isPrivacyAccepted}>
                    Sign Up
                  </button>
                </div>
              </>
            )}
            {!showSignUp && (
              <div className="popupButtonsContainer">
                <button onClick={handlePopupClose}>Close</button>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="footerContent container">
        <div>
          <span>
            Copyright &copy; COLEZZE {currentYear}. All rights reserved.
          </span>
        </div>
        <div className="footerHelp">
          <span className="footerHelpText">ANY QUESTIONS?</span>
          <Link to="/contact" className="footerLinkButton" draggable={false}>
            <span className="footerLink">Contact Us</span>
          </Link>
          <Link to="/privacy" className="footerLinkButton" draggable={false}>
            <span className="footerLink">Privacy Policy</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;