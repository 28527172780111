import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Geolocation API

export const getPhonePrefix = async (): Promise<{ phonePrefix: string }> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/geolocation/phone-prefix`);
    return response.data;
  } catch (error) {
    console.error('Error fetching phone prefix:', error);
    throw error;
  }
};

// Mailing API

export const submitContactForm = async (
  name: string,
  email: string,
  phonePrefix: string,
  phoneNumber: string,
  message: string
): Promise<void> => {
  try {
    await axios.post(`${API_BASE_URL}/mailing/contact`, {
      name,
      email,
      phonePrefix,
      phoneNumber,
      message,
    });
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};

// Newsletter API

export const subscribeToNewsletter = async (email: string): Promise<{ message: string }> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/newsletter/subscribe`, { email });
    return response.data;
  } catch (error) {
    console.error('Error subscribing to newsletter:', error);
    throw error;
  }
};

export const getSubscriber = async (email: string): Promise<any> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/newsletter/subscriber/${email}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching subscriber:', error);
    throw error;
  }
};

export const deleteSubscriber = async (email: string): Promise<any> => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/newsletter/subscriber/${email}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting subscriber:', error);
    throw error;
  }
};