/* eslint-disable prefer-const */
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import "../styles/globals.css";
import Head from "../components/Head";
import React from "react";
import { useRef, useState, useEffect } from "react";
import phonePrefixes from '../data/phonePrefixes.json';
import { getPhonePrefix, submitContactForm } from '../services/api';

type FormValues = {
  name: string;
  email: string;
  phonePrefix: string;
  phoneNumber: string;
  message: string;
};

const Contact = () => {
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [messageLength, setMessageLength] = useState<number>(0);
  const [initialPhonePrefix, setInitialPhonePrefix] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const mouseDownInsidePopup = useRef(false);
  const fetchIPRef = useRef(false);
  const inputRefs = {
    name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phoneNumber: useRef<HTMLInputElement>(null),
    message: useRef<HTMLTextAreaElement>(null),
  };

  useEffect(() => {
    if (fetchIPRef.current) return;
    fetchIPRef.current = true;

    const fetchPhonePrefix = async () => {
      try {
        const data = await getPhonePrefix();
        setInitialPhonePrefix(data.phonePrefix);
      } catch (error) {
        console.error('Failed to fetch phone prefix:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPhonePrefix();
  }, []);

  const handleContainerMouseDown = (event: React.MouseEvent) => {
    mouseDownInsidePopup.current = event.target !== event.currentTarget;
  };

  const handleContainerMouseUp = () => {
    if (!mouseDownInsidePopup.current) {
      setPopupMessage(null);
    }
  };

  const handleContentMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    mouseDownInsidePopup.current = true;
  };

  const handleContentMouseUp = (event: React.MouseEvent) => {
    event.stopPropagation();
    mouseDownInsidePopup.current = true;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Please check the Name"),
    email: Yup.string()
      .required("Email address is required")
      .matches(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/gi, "Please enter a valid email address"), 
    phonePrefix: Yup.string().required("Phone prefix is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{5,15}$/, "Please enter a valid phone number"),
    message: Yup.string()
      .required("Message is required")
      .min(30, "Message should be 30 chars minimum.")
  });

  const initialValues = {
    name: "",
    email: "",
    phonePrefix: initialPhonePrefix,
    phoneNumber: "",
    message: "",
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    try {
      await submitContactForm(
        values.name,
        values.email,
        values.phonePrefix,
        values.phoneNumber,
        values.message
      );
      setPopupMessage('Message was successfully sent.');
    } catch (error) {
      setPopupMessage(`Failed to send message. Please try again later.`);
    } finally {
      Object.values(inputRefs).forEach(ref => { 
        if (ref.current) ref.current.blur();
      });
      resetForm();
      setMessageLength(0);
      setSubmitting(false);
    }
  };

  return (
    <div className="contactContainer">
      <Head title="Colezze | Contact" />
      <div className="contact container2">
        <h2>Contact Us</h2>
        <p>REACH OUT VIA EMAIL AT</p>
        <p>
          <a href="mailto:info@colezze.com" className="emailLink">
            INFO<span className="atSymbol">&#64;</span>
            COLEZZE.COM
          </a>
        </p>
        <p>OR THROUGH THE FORM BELOW,</p>
        <p>AND WE WILL PROVIDE A RESPONSE WITHIN 48 HOURS</p>
        {!loading && (
          <Formik
            initialValues={{ ...initialValues, phonePrefix: initialPhonePrefix }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, touched, errors, setFieldValue }) => (
              <Form style={{ width: "100%" }}>
                <div className="formikField">
                  <label htmlFor="name" className="contactLabel">
                    NAME *
                  </label>
                  <Field
                    innerRef={inputRefs.name}
                    type="text"
                    name="name"
                    placeholder="Name"
                    className={`inputField ${
                      touched.name && errors.name ? "errorInput" : ""
                    }`}
                  />
                  <ErrorMessage name="name" component="p" className="errorText" />
                </div>

                <div className="formikField">
                  <label htmlFor="email" className="contactLabel">
                    EMAIL ADDRESS *
                  </label>
                  <Field
                    innerRef={inputRefs.email}
                    type="email"
                    name="email"
                    placeholder="Email"
                    className={`inputField ${
                      touched.email && errors.email ? "errorInput" : ""
                    }`}
                  />
                  <ErrorMessage name="email" component="p" className="errorText" />
                </div>

                <div className="formikFieldPhone">
                  <label htmlFor="phonePrefix" className="contactLabel">
                    PHONE NUMBER *
                  </label>
                  <div style={{ display: "flex", gap: ".5rem" }}>
                    <Field
                      as="select"
                      name="phonePrefix"
                      className={`phonePrefixSelect ${
                        touched.phoneNumber && errors.phoneNumber
                          ? "errorInput"
                          : ""
                      }`}
                    >
                      {phonePrefixes.map((code) => (
                        <option key={code} value={code}>
                          {code}
                        </option>
                      ))}
                    </Field>
                    <Field
                      innerRef={inputRefs.phoneNumber}
                      type="tel"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      className={`phoneNumberInput ${
                        touched.phoneNumber && errors.phoneNumber
                          ? "errorInput"
                          : ""
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    name="phoneNumber"
                    component="p"
                    className="errorTextPhone"
                  />
                </div>

                <div className="formikField">
                  <div className="labelContainer">
                    <label htmlFor="message" className="contactLabel">
                      MESSAGE *
                    </label>
                    <span id="charCounter" className="contactLabel">{messageLength} / 1000</span>
                  </div>
                  <Field
                    innerRef={inputRefs.message}
                    as="textarea"
                    name="message"
                    placeholder="Message"
                    className={`textareaField ${
                      touched.message && errors.message ? "errorInput" : ""
                    }`}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      let newValue = e.target.value;
                      if (newValue.length > 1000) {
                        newValue = newValue.slice(0, 1000);
                      }
                      setMessageLength(newValue.length);
                      setFieldValue("message", newValue);
                    }}
                  />
                  <ErrorMessage
                    name="message"
                    component="p"
                    className="errorTextMessage"
                  />
                </div>

                <div className="submitContainer">
                  <button
                    type="submit"
                    className="submitButton"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                  <span className="disclaimerText">
                    By pressing the submit button, I agree to COLEZZE contacting me by email and/or phone. I also understand that any information shared in this form is subject to COLEZZE’s <a href="/privacy">Privacy Policy</a>.
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      {popupMessage && (
        <div
          className="popupContainer"
          onMouseDown={handleContainerMouseDown}
          onMouseUp={handleContainerMouseUp}
        >
          <div
            className="popupContent"
            onMouseDown={handleContentMouseDown}
            onMouseUp={handleContentMouseUp}
          >
            <p className="popupTitle">REACH OUT TO THE COLEZZE TEAM</p>
            <hr className="divider" />
            <div style={{ marginBottom: "10px" }}>
              <p className="popupMessage">{popupMessage}</p>
            </div>
            <button onClick={() => setPopupMessage(null)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;