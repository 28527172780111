import Head from "../components/Head";
import "../styles/globals.css";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacyPolicyContainer">
      <Head title="Colezze | Privacy Policy" />
      <div className="privacyPolicyHeader">
        <h1>Privacy Policy</h1>
        <p className="effectiveDate">Effective Date: August 16, 2024</p>
      </div>
      <div className="privacyPolicyContent">
        <section>
          <h3>
            <span className="sectionNumber">1.</span> Scope of This Policy
          </h3>
          <p>
            Colezze ("<span id="bold-text">we</span>", "<span id="bold-text">us</span>", "<span id="bold-text">our</span>") respects your privacy and values your trust. This Privacy Policy explains how we collect, use, and share your personal information when you visit our website at colezze.com (the "<span id="bold-text">Website</span>"), purchase products from us, or use any of our services (collectively, the "<span id="bold-text">Service</span>"). If you have any questions about this Privacy Policy or our practices, please contact us using the information provided in the <span style={{ fontStyle: 'italic' }}>How to Contact Us</span> section.
          </p>

          <h3>
            <span className="sectionNumber">2.</span> Agreement to Terms
          </h3>
          <p>
            By accessing or using our Service, you agree to the terms outlined in this Privacy Policy. If you do not agree with these terms, please discontinue use of the Service immediately.
          </p>

          <h3>
            <span className="sectionNumber">3.</span> Information We Collect
          </h3>
          <p>
            We may collect the following categories of personal information:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>
              <strong>Identifiers:</strong> Name, email address and phone number.
            </li>
            {/* <li>
              <strong>Financial Information:</strong> Payment details such as credit or debit card information, billing address, and order history.
            </li> */}
            {/* <li>
              <strong>Inferences:</strong> Preferences, characteristics, and behaviors based on your interactions with our Website.
            </li> */}
            <li>
              <strong>Device Information:</strong> Device type, browser type, online identifiers, IP address, and geolocation data.
            </li>
            {/* <li>
              <strong>Internet Activity:</strong> Information about your browsing activity on our Website, including pages visited and time spent.
            </li> */}
          </ul>

          <h3>
            <span className="sectionNumber">4.</span> Cookies
          </h3>
          <p>
            We use cookies and similar technologies to collect information automatically. Cookies help us understand your preferences, improve your experience on our Website, and deliver personalized content and ads. You can manage your cookie preferences through your browser settings, but some features of our Website may not function properly if cookies are disabled.
          </p>

          <h3>
            <span className="sectionNumber">5.</span> Children’s Privacy
          </h3>
          <p>
            Our Service is not intended for children under the age of 16, and we do not knowingly collect personal information from individuals under this age. If you become aware of a child under 16 using our Service, please contact us immediately.
          </p>

          <h3>
            <span className="sectionNumber">6.</span> Our Use of Personal Information
          </h3>
          <p>
            We use the personal information we collect for the following purposes:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            {/* <li>To process and fulfill your orders.</li> */}
            <li>To provide customer support and respond to your inquiries.</li>
            <li>To send promotional communications and updates about Colezze products and services.</li>
            {/* <li>To personalize your experience on our Website.</li> */}
            {/* <li>To analyze and improve our Service.</li> */}
            <li>To comply with legal obligations and protect our rights.</li>
          </ul>

          <h3>
            <span className="sectionNumber">7.</span> Our Disclosure of Personal Information
          </h3>
          <p>
            We may share your personal information with:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            {/* <li>Service providers who assist us with operations, such as payment processing and order fulfillment.</li> */}
            <li>Advertising partners to provide you with relevant marketing content.</li>
            <li>Legal authorities or third parties when required to comply with legal obligations or to protect our rights.</li>
          </ul>

          <h3>
            <span className="sectionNumber">8.</span> Your Privacy Choices
          </h3>
          <p>
            You have the right to access, correct, or delete your personal information. You may also opt out of receiving promotional communications from Colezze at any time by following the instructions provided in the communication or by contacting us directly. Note that you cannot opt out of transactional emails related to your orders.
          </p>

          <h3>
            <span className="sectionNumber">9.</span> Security
          </h3>
          <p>
            We implement reasonable security measures to protect your personal information. However, no online transmission or storage system can be guaranteed to be 100% secure. We encourage you to take precautions, such as keeping your password confidential, to protect your personal information.
          </p>

          <h3>
            <span className="sectionNumber">10.</span> Additional Disclosures for Residents of Certain Regions
          </h3>
          <p>
            Depending on your region, additional privacy rights and disclosures may apply. For example, residents of California, Canada, the European Union, Brazil, Australia, and New Zealand may have specific rights under local laws. Please refer to the relevant section below for more information.
          </p>

          <h3>
            <span className="sectionNumber">11.</span> Links to Other Websites
          </h3>
          <p>
            Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We recommend that you review the privacy policies of any website you visit.
          </p>

          <h3>
            <span className="sectionNumber">12.</span> Effective Date and Changes to This Privacy Policy
          </h3>
          <p>
            This Privacy Policy is effective as of the date listed at the top. We may update this policy from time to time to reflect changes in our practices. Any changes will be posted on this page, and the effective date will be revised accordingly. We encourage you to review this policy periodically.
          </p>

          <h3 id="contactSection">
            <span className="sectionNumber">13.</span> How to Contact Us
          </h3>
          <p id="contactSection">
            If you have any questions about this Privacy Policy or our data practices, please contact us at <a href="mailto:info@colezze.com" className="emailLink">INFO<span className="atSymbol">@</span>COLEZZE.COM</a>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;