import Head from "../components/Head";
import "../styles/globals.css";
import React from "react";
import background from "../images/Colezze-©-jacket-box-copy.png"

const Collections = () => {
  return (
    <div className="collectionsContainer wrapper">
      <Head title="Colezze | Collections" />
      <div className="collection-img">
        <img
          src={background}
          alt="Background"
          className="backgroundImage"
        />
      </div>
      <div className="collection">
        <h2>AUTUMN-WINTER 2025</h2>
        <p>COMING SOON...</p>
      </div>
    </div>
  );
};

export default Collections;