import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SidebarProvider } from "./contexts/sidebarContext";

import Layout from "./components/Layout";

import Collections from "./pages/Collections";
import PrivacyPolicy from "./pages/privacyPolicy";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import React from "react";

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter basename="/">
        <SidebarProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="contact" element={<Contact />} />
              <Route path="collections" element={<Collections />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="404" element={<NoPage />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
          </Routes>
        </SidebarProvider>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;